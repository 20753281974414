import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { AppProps } from 'next/app'
import Modal from 'react-modal'
import { SharedLoadingIndicatorContextProvider } from 'shared-loading-indicator'
import { PageNavigationLoadingTracker } from '../app/components/PageNavigationLoadingTracker'
import { ScrollLock } from '../app/components/ScrollLock'
import '../app/styles/globals.sass'
import { HydratingRoot } from '../libs/next/hydratation'

Modal.setAppElement('#__next')

function MyApp({ Component, pageProps }: AppProps) {
	const queryClient = new QueryClient()
	return (
		<HydratingRoot>
			<SharedLoadingIndicatorContextProvider>
				<QueryClientProvider client={queryClient}>
					<ScrollLock>
						<PageNavigationLoadingTracker />
						<Component {...pageProps} />
					</ScrollLock>
				</QueryClientProvider>
			</SharedLoadingIndicatorContextProvider>
		</HydratingRoot>
	)
}
export default MyApp
